<template>
  <div v-if="details" class="user-comment">
    <div class="header">
      <div class="avatar">
        <img :src="details.saleHeadImgUrlOss" alt="">
      </div>
      <div class="cont">
        <div class="name">{{ details.realName }}</div>
        <div class="tip">
          <div class="item" style="background: #DEF0FF; color: #2697FF;">{{ details.creditTypeTitle }}</div>
          <div class="item">￥{{ details.loan }}万</div>
          <template v-for="(item, index) in details.products">
            <div v-if="index < 3" :key="item" class="item">{{ item | productString }}</div>
          </template>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="head">
        <div class="text">您对该经理人满意吗？</div>
        <div class="per">
          <span style="padding-right: 10px">匿名</span> <van-checkbox v-model="form.hide" checked-color="#FF7700" />
        </div>
      </div>
      <div class="cont">
        <div class="group">
          <div class="label">专业程度</div>
          <div class="input">
            <van-rate v-model="form.majorStar" color="#ff7700" void-color="#FF7700" />
          </div>
          <div class="text">{{ stars[form.majorStar] }}</div>
        </div>
        <div class="group">
          <div class="label">服务态度</div>
          <div class="input">
            <van-rate v-model="form.serviceStar" color="#ff7700" void-color="#FF7700" />
          </div>
          <div class="text">{{ stars[form.serviceStar] }}</div>
        </div>
        <div class="textarea">
          <van-field v-model="form.content" type="textarea" placeholder="本次贷款是否解决了您的需求？留下您对该经理人想说的话吧！" maxlength="100" show-word-limit />
        </div>
      </div>
    </div>
    <div class="footer">
      <van-button type="primary" size="normal" round block color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)" @click="addComment">提交</van-button>
    </div>
  </div>
</template>
<script>
import { addComment } from '@/api/user'
import { getOssUrl } from '@/utils/oss'
export default {
  filters: {
    productString(str) {
      if (str.length > 8) {
        return str.substring(0, 8) + '...'
      } else {
        return str
      }
    }
  },
  data() {
    return {
      form: {
        userId: '',
        orderId: '',
        hide: false, // 0匿名 1实名
        content: '',
        majorStar: 0,
        serviceStar: 0
      },
      avatarUrl: '',
      stars: ['', '非常差', '差', '一般', '好', '非常好'],
      details: null
    }
  },
  async mounted() {
    this.details = this.$route.params.details || {}
    this.$set(this.details, 'saleHeadImgUrlOss', await getOssUrl(this.details.saleHeadImgUrl))
    this.form.orderId = this.details.orderId
    this.form.userId = this.details.saleId
    if (!this.details.orderId) return this.$router.go(-1)
  },
  methods: {
    async addComment() {
      const params = { ...this.form }
      params.hide = this.form.hide ? 0 : 1
      console.log(params)
      if (params.majorStar === 0) {
        return this.$toast('请对专业程度进行评分！')
      }
      if (params.serviceStar === 0) {
        return this.$toast('请对服务态度进行评分！')
      }
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      await addComment(params)
      loading.clear()
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.user-comment{
  padding: 10px;
  .header {
    display: flex;
    .avatar {
      flex: none;
      width: 40px;
      height: 40px;
      padding-top: 15px;
      margin-right: 10px;
    }
    .cont {
      padding: 15px 0;
      .name {
        font-size: 16px;
        color: #2E3135;
      }
      .tip {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        .item {
          padding: 5px 10px;
          margin: 0 10px 10px 0;
          color: #FF7700;
          border-radius: 4px;
          background: #fde9d7;
        }
      }
    }
  }
  .content {
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.06);
    background: #fff;
    .head {
      display: flex;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #F3F3F5;
      .text {
        flex: 1;
      }
      .per {
        display: flex;
        align-items: center;
      }
    }
    .cont {
      padding: 8px 0;
      .group {
        display: flex;
        align-items: center;
        padding: 8px 0;
        .label {
          padding-right: 15px;
        }
        .input {
          padding-right: 15px;
        }
        .text {
          color: #FF7700;
        }
      }
      .textarea {
        padding-top: 10px;
        .van-cell {
          background:#FAFAFA;
        }
      }
    }
  }
  .footer {
    padding-top: 20px;
  }
}
</style>
